import React from 'react'

import Empty from 'components/Empty'
import NextHead from 'components/NextHead'

const Error404 = () => {
  return (
    <React.Fragment>
      <NextHead title="404" />
      <Empty description="Esta página não existe" />
    </React.Fragment>
  )
}

export default Error404
